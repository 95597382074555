import type { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';

import type { Nullable } from '../../types';

interface GlobalAppState {
  getFlowAPI: () => Nullable<EditorScriptFlowAPI>;
  setFlowAPI: (flowAPI: EditorScriptFlowAPI) => void;
}

const initGlobalAppState = (): GlobalAppState => {
  let _flowAPI: Nullable<EditorScriptFlowAPI> = null;

  return {
    getFlowAPI: () => _flowAPI,
    setFlowAPI: (flowAPI) => (_flowAPI = flowAPI),
  };
};

export const globalAppState = initGlobalAppState();
