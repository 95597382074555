import stylesParams from '../../components/ProfileCard/stylesParams';
import { ProfileLayout } from '../../types';

export const PROFILE_LAYOUT_PARAM_KEY = stylesParams.profileLayout.key!;

export const SANTA_MEMBERS_APP_DEF_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';
export const MY_ACCOUNT_APP_DEF_ID = '14cffd81-5215-0a7f-22f8-074b0e2401fb';

export const PROFILE_WIDGET_HORIZONTAL_LAYOUT_STYLE_PARAM = {
  type: 'number' as const,
  key: PROFILE_LAYOUT_PARAM_KEY,
  param: {
    value: ProfileLayout.FullWidth as number,
  },
};

export const PROFILE_WIDGET_SIDEBAR_LAYOUT_STYLE_PARAM = {
  type: 'number' as const,
  key: PROFILE_LAYOUT_PARAM_KEY,
  param: {
    value: ProfileLayout.Card as number,
  },
};
