import type { EditorScriptFlowAPI } from '../constants/types';

enum ErrorSeverity {
  Fatal = 'fatal',
  Error = 'error',
  Warning = 'warning',
  Log = 'log',
  Info = 'info',
  Debug = 'debug',
  Critical = 'critical',
}

let panorama: EditorScriptFlowAPI['panoramaClient'];

export function initMonitoring(
  panoramaClient: EditorScriptFlowAPI['panoramaClient'],
) {
  panorama = panoramaClient;
}

export function interactionStarted(interactionName: string) {
  try {
    panorama?.transaction(interactionName).start();
  } catch (e) {
    const err = `Failed to start interaction, reason: ${e}`;
    panorama?.errorMonitor().reportError(new Error(err));
  }
}

export function interactionEnded(interactionName: string) {
  try {
    panorama?.transaction(interactionName).finish();
  } catch (e) {
    const err = `Failed to end interaction, reason: ${e}`;
    panorama?.errorMonitor().reportError(new Error(err));
  }
}

export function log(message: string) {
  panorama
    ?.errorMonitor()
    .reportError(new Error(message), { severity: ErrorSeverity.Info });
}

export async function toMonitored<T extends Function>(
  interactionName: string,
  promise: T,
) {
  try {
    interactionStarted(interactionName);
    const response = await promise();
    interactionEnded(interactionName);
    return response;
  } catch (error) {
    panorama?.errorMonitor().reportError(error as Error);
    throw error;
  }
}
