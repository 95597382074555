import { MEMBERS_AREA_V2 } from '@wix/app-definition-ids';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

type ProfilePageBobApi = {
  hideProfileCardContainer: () => Promise<void>;
};

const geProfilePageBobApi = (editorSDK: FlowEditorSDK) => {
  return editorSDK.document.application.getPublicAPI('', {
    appDefinitionId: MEMBERS_AREA_V2,
  }) as Promise<ProfilePageBobApi | undefined>;
};

export const hideProfileCardContainer = async (editorSDK: FlowEditorSDK) => {
  const profilePageBobApi = await geProfilePageBobApi(editorSDK);

  return profilePageBobApi?.hideProfileCardContainer();
};
